import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '3.5em',
    background: 'rgba(45,53,76,0.05)',
    border: 'none',
    borderRadius: '4px',
    borderColor: 'rgba(45,53,76,0.05)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '0.5em',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
    paddingLeft: '0.5em',
  }),
};

function Dropdown(props) {
  return (
    <Select
      styles={customStyles}
      value={props.input.value}
      onChange={props.input.onChange}
      placeholder="Which course did you do?"
      options={[
        { value: 'wd', label: 'Web Development' },
        { value: 'ux', label: 'UX/UI Design' },
        { value: 'da', label: 'Data Analytics' },
      ]}
    />
  );
}

export default Dropdown;
