import React from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { navigate } from 'gatsby';
import { Box } from 'rebass/styled-components';

import CourseDropdown from './CourseDropdown';
import EmailInput from './EmailInput';
import SubmitButton from './SubmitButton';

const AlumniForm = (props) => {
  const { onError } = props;
  const onSubmit = async (values) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_SALESFORCE_CONNECTOR_URL}/v1/onboardings`,
        {
          method: 'POST',
          body: JSON.stringify({
            email: values.email,
            track: values.course.value,
          }),
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.status !== 200) {
        onError(true);
        return { [FORM_ERROR]: 'Could not onboard student' };
      } else {
        const body = await response.json();
        if (body.result.success) {
          navigate('/success/');
        } else {
          navigate('/existing/');
        }
      }
    } catch (error) {
      onError(true);
      return { [FORM_ERROR]: 'Could not onboard student' };
    }
  };

  const validate = (values) => {
    if (/\S+@\S+\.\S+/.test(values.email) && values.course) {
      return {};
    } else {
      return { [FORM_ERROR]: 'Missing required fields' };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, error }) => (
        <form onSubmit={handleSubmit}>
          <Field name="email" component={EmailInput} />
          <Box mt="2em">
            <Field name="course" component={CourseDropdown} />
          </Box>
          <Box mt="2em">
            <SubmitButton
              disabled={error || submitting}
              submitting={submitting}
            />
          </Box>
          <FormSpy
            subscription={{ submitError: true, submitting: true }}
            onChange={(state) => {
              const { submitError, submitting } = state;
              onError(submitError && !submitting);
            }}
          />
        </form>
      )}
    />
  );
};

export default AlumniForm;
