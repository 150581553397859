import React from 'react';
import styled from 'styled-components';

import Spinner from './Spinner';
import { device } from '../constants/breakpoints';

const Button = styled.button`
  width: 100%;
  height: 3em;
  font: 14px Roboto;
  font-weight: bold;
  background: ${(props) => (props.disabled ? '#abe7fd' : '#2dc5fa')};
  color: #ffffff;
  border: none;
  border-radius: 4px;

  @media ${device.tablet} {
    font: 20px Roboto;
  }
`;

function SubmitButton(props) {
  const { disabled, submitting } = props;
  return (
    <Button type="submit" disabled={disabled}>
      {submitting ? <Spinner /> : 'Get access to our new materials'}
    </Button>
  );
}

export default SubmitButton;
