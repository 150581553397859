import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  /* padding: 1.25em 0; */
  width: 100%;
  font: 400 16px Roboto;
  padding-left: 1em;
  height: 3.5em;
  background: rgba(45, 53, 76, 0.05);
  border: none;
  border-radius: 4px;
  border-color: rgba(45, 53, 76, 0.05);
`;

function EmailInput(props) {
  return (
    <Input
      type="text"
      placeholder="Email"
      value={props.input.value}
      onChange={props.input.onChange}
    />
  );
}

export default EmailInput;
