import React, { useState } from 'react';
import { Box } from 'rebass/styled-components';

import SEO from '../components/SEO';
import AlumniForm from '../components/AlumniForm';
import { Container, Header, ErrorContainer } from '../styles/PageLayout';
import { Headline, Subtitle } from '../styles/Typography';
import Logo from '../components/Logo';
import ErrorNotice from '../components/ErrorNotice';

const HomePage = () => {
  const [hasError, setError] = useState(false);
  return (
    <>
      <SEO title="Ironhack Alumni Platform Migration" />
      <Header>
        <Logo />
      </Header>
      <Container>
        <Box textAlign="center">
          <Headline>Ironhackers, we have good news for you! 🎉</Headline>
        </Box>
        <Box mt="1em" textAlign="center" color="rgba(45,53,76,0.8)">
          <Subtitle>
            We've upgraded the access of all alumni to the most recent Ironhack
            content. To do so, you just need to enter your information here:
          </Subtitle>
        </Box>
        <Box mt="2em" width="100%">
          <AlumniForm onError={setError} />
        </Box>
      </Container>
      <Box mt="2em">
        {hasError && (
          <ErrorContainer>
            <ErrorNotice />
          </ErrorContainer>
        )}
      </Box>
    </>
  );
};

export default HomePage;
