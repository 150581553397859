import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  text-align: center;
  font-size: 0.875em;
`;

const HelpLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
`;

function ErrorNotice(props) {
  return (
    <Error>
      We don’t have that mail in our database. Maybe you signed with us with a
      different one?
      <br />
      <br /> If not, please{' '}
      <HelpLink
        href="https://forms.clickup.com/f/2b3h2-3732/NFKX675JKWVWGA1FB3"
        target="_blank"
        rel="noopener noreferrer"
      >
        click here
      </HelpLink>{' '}
      and send us more information so we can help you.
    </Error>
  );
}

export default ErrorNotice;
